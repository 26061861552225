import React from "react";

const PromptDetails = ({ promptTitle }) => {
  return (
    <div>
      <p className="prompt-title no-select">{promptTitle}</p>
      {/* <div className="note">
        *NOTE: This sample test is not recording your response.
      </div> */}
    </div>
  );
};

export default PromptDetails;
