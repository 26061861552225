import { Typography, Divider, Box, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import Sidebar from '../Common/Sidebar';
import TaskDisplay from '../Common/TaskDisplay';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ComingSoon from '../Common/ComingSoon';

const Listening = () => {
    return (
        <div className="reading">
            <Sidebar className="sidebar" />
            <div className="central-control">
                <div className="central-control">
                    <h3>Listening Module</h3>
                    <p style={{ color: 'black' }}>The CELPIP Listening Test is designed to assess your ability to understand spoken English in everyday situations. The test consists of six parts, each focusing on different listening scenarios. Below are detailed summaries of each part:</p>
                    <ComingSoon />
                    <TaskDisplay selectedModule="listening" />
                </div>
            </div>
        </div>
    );
};

export default Listening;
