import React, { useState, useEffect } from "react";
import TaskList from "./TaskList";
import PromptList from "./PromptList";
import BackButton from "./BackButton";
import PracticePage_Base from "./PracticePage_Base";
import WritingPracticePage from "../WritingCelpip/WritingPracticePage_Base";
import BackLink from "./BackLink";
import { useNavigate, useParams } from "react-router-dom";

const CentralControl = ({
  selectedModule,
  moduleDesc,
  selectedTask,
  prompts,
  allPromptsData,
  onBack,
  onTaskSelect,
  loading,
}) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const { taskType, promptId } = useParams();
  const navigate = useNavigate();

  // console.log("Selected task in Central control during enter: ", selectedTask);
  useEffect(() => {
    if (promptId) {
      handlePromptSelect(promptId);
    }
  }, [taskType, promptId]);

  const handlePromptSelect = (prompt) => {
    console.log("handlePromptSelect", prompt);
    setSelectedPrompt(prompt);
    navigate(`/${selectedModule}/${taskType}/${prompt}`);
  };

  const handleBackToPromptList = () => {
    navigate(`/${selectedModule}/${taskType}/`);
    setSelectedPrompt(null);
  };

  if (selectedModule === "speaking" || selectedModule === "writing") {
    if (selectedPrompt) {
      return (
        <PracticePage_Base
          selectedModule={selectedModule}
          selectedTaskType={selectedTask}
          allPromptList={prompts}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={handleBackToPromptList}
        />
      );
    }
  }

  if (loading) {
    return <p>Loading task data...</p>; // Display loading message
  }

  if (selectedTask && prompts.length > 0) {
    return (
      <React.Fragment>
        <BackLink onBack={onBack} title="Back to Task Types" />
        <div className="central-control">
          <h3>
            {selectedModule} Module - {selectedTask}
          </h3>
          <PromptList
            prompts={prompts}
            onPromptSelect={handlePromptSelect}
            selectedModule={selectedModule}
            selectedTask={selectedTask}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="central-control">
      <h3>{selectedModule} Module</h3>
      <p style={{ color: "black" }}>{moduleDesc}</p>
      <TaskList selectedModule={selectedModule} onSelectTask={onTaskSelect} />
    </div>
  );
};

export default CentralControl;
