import React from "react";
import CircleOfAchievement from "../Components/CircleOfAchievement";
import { data } from "../data";
import CapturedResponse from "../Components/CapturedResponse";
export const Results = () => {
  return (
    <React.Fragment>
      <CapturedResponse></CapturedResponse>
      <CircleOfAchievement data={data} />
    </React.Fragment>
  );
};
export default Results;
