import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React from "react";
import {
  HomeLayout,
  Test,
  Speaking,
  Writing,
  Samples,
  Results,
  WritingTest,
  ErrorPage,
} from "./pages";
import { UserProvider } from "./Components/UserProvider";
import { HomePageTestSection } from "./Components/HomePageTestSection";
import TestDetails from "./Celpip/TestDetails";
import Reading from "./pages/Reading";
import Listening from "./pages/Listening";
import FAQs from "./pages/FAQs";
import ScoringCriteria from "./Celpip/ScoringCriteria";
import TestFormat from "./Celpip/TestFormat";
import CLBComparison from "./Celpip/CLBComparison";
import PreparationTips from "./Celpip/PreparationTips";
import BandDescriptors from "./Celpip/BandDescriptors";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <HomePageTestSection />,
      },
      {
        path: "test",
        element: <Test />,
      },
      {
        path: "speaking",
        element: <Speaking />,
      },
      {
        path: "speaking/:taskType",
        element: <Speaking />, // Reuse Speaking component for task selection
      },
      {
        path: "speaking/:taskType/:promptId",
        element: <Speaking />, // Reuse Speaking component for task selection
      },
      {
        path: "listening",
        element: <Listening />,
      },
      {
        path: "writing",
        element: <Writing />,
      },
      {
        path: "writing/:taskType",
        element: <Writing />,
      },
      {
        path: "writing/:taskType/:promptId",
        element: <Writing />,
      },
      {
        path: "reading",
        element: <Reading />,
      },
      {
        path: "FAQs",
        element: <FAQs />,
      },
      {
        path: "results",
        element: <Results />,
      },
      {
        path: "testdetails",
        element: <TestDetails />,
      },
      {
        path: "testformat",
        element: <TestFormat />,
      },
      {
        path: "clbcomparison",
        element: <CLBComparison />,
      },
      {
        path: "scoringcriteria",
        element: <ScoringCriteria />,
      },
      {
        path: "tips",
        element: <PreparationTips />,
      },
      {
        path: "banddescriptors",
        element: <BandDescriptors />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);
function App() {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
