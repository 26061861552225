import React from "react";
import { PracticeTest } from "../Components/PracticeTest";
import { UserProvider, useUser } from "../Components/UserProvider";
export const Test = () => {
  const { userId } = useUser();
  return (
    <UserProvider>
      <React.Fragment>
        <PracticeTest userId={userId}></PracticeTest>
      </React.Fragment>
    </UserProvider>
  );
};
export default Test;
