import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../SpeakingCelpip/style_speakingCelpip.css'

const Sidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust the size as needed

  if (isMobile) {
    return null; // Hide sidebar on small screens
  }

  //TODO: add vocabulary
  return (
    <div className='sidebar'>
      <ul style={{marginLeft: '20px'}}>
        <li><Link to="/speaking">Speaking Test</Link></li>
        <li><Link to="/listening">Listening Test</Link></li>
        <li><Link to="/reading">Reading Test</Link></li>
        <li><Link to="/writing/Task1">Writing Task 1</Link></li>
        <li><Link to="/speaking/Task1">Speaking Task 1</Link></li>
        <li><Link to="/speaking/Task2">Speaking Task 2</Link></li>
        <li><Link to="/writing/Task2">Writing Task 2</Link></li>
        <li><Link to="/speaking/Task5">Speaking Task 5</Link></li>
        <li><Link to="/speaking/Task8">Speaking Task 8</Link></li>
        {/* <li><Link to="/vocabulary">Vocabulary</Link></li> */}
        <li><Link to="/tips">Tips</Link></li>
        <li><Link to="/scoringcriteria">Scoring Criteria</Link></li>
        <li><Link to="/banddescriptors">Band Descriptors</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
