import React from "react";
import { Typography, Box } from "@mui/material";
import Sidebar from "../Common/Sidebar";

const PreparationTips = () => {
  return (
    <div className="reading">
            <Sidebar className="sidebar" />
            <div className="central-control">
            <div className="central-control">
    <Box sx={{ textAlign: "justify" }}>
      {/* General Tips Heading */}
      <Typography variant="h5" gutterBottom>
        <strong>General Tips for CELPIP Preparation:</strong>
      </Typography>

      {/* General Tips Content */}
      <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
        <li>
          <Typography>
            <strong>Understand the Test Format:</strong> Familiarize yourself with the structure of each test section (Listening, Reading, Writing, and Speaking). Knowing what to expect will help you manage your time effectively during the test.
            <br />
            <a href="/testformat" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Check Test Format
    </a>
              </Typography>
        </li>
        <br/>
        
        <li>
          <Typography>
            <strong>Enhance Your Speaking Skills:</strong> Practice speaking English in everyday situations. Record yourself and listen for areas where you can improve your pronunciation, fluency, and coherence.
            <br />
            <a href="/speaking" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Practice Speaking 
    </a></Typography>
        </li>
        <br/>
        
        <li>
          <Typography>
            <strong>Work on Your Writing:</strong> Practice writing emails, essays, and responses to survey questions. Focus on organizing your thoughts clearly and using a variety of sentence structures.
            <br />
            <a href="/writing" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Practice Writing Tasks
    </a></Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Practice Regularly:</strong> Consistent practice is key to improving your English language skills. Use sample questions, practice tests, and study materials that mirror the actual CELPIP test format.
            {/* <br />
            <a href="/testformat" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Practice Writing
    </a> */}
          </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Practice Listening Skills:</strong> Enhance your listening ability by watching English movies, TV shows, and listening to podcasts. Pay attention to different accents and practice understanding the context of conversations.
            </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Focus on Your Weak Areas:</strong> Identify the sections where you need the most improvement and dedicate extra time to practicing those areas. This targeted approach will help you boost your overall score.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Improve Your Vocabulary:</strong> Expanding your vocabulary is essential for both the Writing and Speaking sections. Read a variety of materials, such as newspapers, books, and online articles, to learn new words and phrases.
          </Typography>
        </li>
        <br/>
        
       
        <li>
          <Typography>
            <strong>Take Timed Practice Tests:</strong> Simulate the test environment by taking timed practice tests. This will help you get used to the pressure of completing tasks within a set time limit.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Review and Analyze Your Mistakes:</strong> After completing practice tests or exercises, review your answers and understand why you made certain mistakes. This reflection will help you avoid similar errors on the actual test.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Join Study Groups or Take a Prep Course:</strong> Consider joining a study group or enrolling in a CELPIP preparation course. Interacting with others can provide valuable insights and motivation.
          </Typography>
        </li>
        <br/>
        <li>
          <Typography>
            <strong>Stay Relaxed and Confident:</strong> On the day of the test, ensure you are well-rested and arrive early at the test center. Stay calm, confident, and focused on performing your best.
          </Typography>
        </li>
      </ul>
      <br/>
      {/* Specific Section Tips */}
      <Typography variant="h5" gutterBottom>
        <strong>Specific Section Tips:</strong>
      </Typography>
      <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
        <li>
          <Typography>
            <strong>Listening:</strong> Take notes while listening to the audio. Focus on understanding the main ideas and details.
            <br />
           <p> For more details about Listening Test <a href="/listening" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Click here
    </a></p>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Reading:</strong> Skim through the passage first to get the gist, then read it carefully to answer the questions.
            <br />
            <p> For more details about Reading Test <a href="/reading" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
            Click here
    </a></p>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Writing:</strong> Plan your response before you start writing. Ensure your ideas are well-organized and clearly presented.
            <p> For more details about Writing Test <a href="/writing" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Click here
    </a></p></Typography>
        </li>
        <li>
          <Typography>
            <strong>Speaking:</strong> Speak clearly and at a natural pace. Don’t rush, and make sure to fully answer the prompts.
            <p> For more details about Speaking Test <a href="/speaking" style={{ color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
      Click here
    </a></p></Typography>
        </li>
      </ul>
    </Box>
    </div>
    </div>
    </div>
  );
};

export default PreparationTips;
