import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Sidebar from '../Common/Sidebar';

const FAQs = () => {
  return (
    <div className="reading">
            <Sidebar className="sidebar" />
            <div className="central-control">
            <div className="central-control">
            <h3>FAQs | CELPIP Exam</h3>
            <p style={{ color: 'black' }}>Find answers to the most commonly asked questions about CELPIP exam. Explore the questions below for quick solutions and helpful information.</p>
            <br/>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What is the CELPIP Test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CELPIP (Canadian English Language Proficiency Index Program) test is an English language proficiency test designed to assess your ability to use English in everyday situations. It is widely accepted for immigration, citizenship, and professional designation purposes in Canada.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What are the different types of CELPIP tests?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There are two types of CELPIP tests:
            <ul>
              <li><strong>CELPIP-General</strong>: Assesses Listening, Reading, Writing, and Speaking skills. It is used for permanent residency applications and some professional designations.</li>
              <li><strong>CELPIP-General LS</strong>: Assesses Listening and Speaking skills only. It is used primarily for Canadian citizenship applications.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How long is the CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li><strong>CELPIP-General</strong>: The test takes about 3 hours to complete and is done in one sitting.</li>
              <li><strong>CELPIP-General LS</strong>: The test takes about 1 hour and 10 minutes.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How is the CELPIP test scored?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CELPIP test uses a 12-point scale, with each skill (Listening, Reading, Writing, and Speaking) receiving a score from 1 to 12. Scores are based on specific criteria for each section, and the test is designed to assess proficiency in Canadian English.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Where can I take the CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The CELPIP test is available at authorized test centers across Canada and in select international locations. You can check the availability of test centers and dates on the official CELPIP website.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How do I register for the CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can register for the CELPIP test online through the official CELPIP website. Simply create an account, choose your preferred test type, location, and date, and complete the registration process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How much does the CELPIP test cost?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The cost of the CELPIP test varies depending on your location and the type of test you are taking. Prices are listed on the CELPIP website during the registration process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How long are CELPIP test results valid?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            CELPIP test results are valid for two years from the date of the test. After this period, you may need to retake the test if your results are required for immigration or other purposes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>When will I receive my CELPIP test results?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Test results are typically available online within 4-5 calendar days after your test date. You will receive an email notification when your results are ready.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I request a re-evaluation of my CELPIP scores?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, if you believe that your scores do not accurately reflect your abilities, you can request a re-evaluation of your Writing and Speaking scores. There is a fee for re-evaluation, and the process typically takes 2-4 weeks.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What should I bring to the test center on the day of my CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You must bring a valid, government-issued photo ID (such as a passport or driver’s license) that matches the name you used to register for the test. Make sure to arrive at the test center at least 30 minutes before your scheduled test time.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I reschedule or cancel my CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can reschedule or cancel your test up to 9 days before your test date. However, there is a fee for rescheduling or canceling. If you cancel more than 9 days before your test date, you may be eligible for a partial refund.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Are there any accommodations for test-takers with disabilities?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, CELPIP provides accommodations for test-takers with disabilities. You can request accommodations during the registration process. Supporting documentation may be required.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How can I prepare for the CELPIP test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There are various preparation resources available, including sample questions, practice tests, study guides, and preparation courses. Regular practice and familiarity with the test format are key to success.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Is CELPIP available in languages other than English?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, the CELPIP test is only available in English. It is specifically designed to assess English language proficiency for Canadian immigration and professional purposes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What happens if I miss my test appointment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you miss your test appointment without proper notification or rescheduling, your test fee will not be refunded, and you will need to register and pay for a new test.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I take the CELPIP test outside of Canada?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, CELPIP test centers are available in select international locations. Check the CELPIP website for the availability of international test centers near you.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How does CELPIP compare to other English proficiency tests?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            CELPIP is unique in its focus on Canadian English and everyday language use. Unlike some other tests, CELPIP is fully computer-delivered and can be completed in one sitting.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Who accepts CELPIP test scores?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            CELPIP scores are accepted by Immigration, Refugees and Citizenship Canada (IRCC), various provincial nominee programs (PNP), professional licensing bodies, educational institutions, and employers across Canada.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What should I do if I need help during the test?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you need assistance during the test, raise your hand to alert a test supervisor. They can help with technical issues or clarify test instructions but cannot assist with answering test questions.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </div>
    </div>
  );
}

export default FAQs;
