import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, Footer_New } from "../Common";
import ResponsiveAppBar from "../Common/ResponsiveAppBar";

export const HomeLayout = () => {
  return (
    <React.Fragment>
      <ResponsiveAppBar />
      <Outlet />
      <Footer_New />
    </React.Fragment>
  );
};

export default HomeLayout;
