import CentralControl from "../SpeakingCelpip/CentralControl";
import Sidebar from "../Common/Sidebar";
import React, { useState, useEffect } from "react";
import "./style_pages.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const Writing = () => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [allPromptsData, setAllPromptsData] = useState([]);
  const navigate = useNavigate();
  const { taskType, promptId } = useParams();

  useEffect(() => {
    fetchData(taskType);
  }, [taskType]); // Run whenever the taskType in the URL changes

  const handleTaskSelect = (taskType) => {
    // Only update the URL if promptId is not present
    if (!promptId) {
      // Update URL to reflect task selection without promptId
      navigate(`/writing/${taskType}/`);
    }
  };

  const fetchData = (taskType) => {
    setLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const localStorageKey = `prompts_writing_${taskType}`; // Key for local storage

    // localStorage.removeItem(localStorageKey);
    // Check if data exists in local storage
    const storedPrompts = localStorage.getItem(localStorageKey);

    if (storedPrompts) {
      // If found in local storage, use it
      const parsedData = JSON.parse(storedPrompts);
      setAllPromptsData(parsedData);
      const promptIDs = parsedData.map((item) => item.PromptID);
      setPrompts(promptIDs);
      setLoading(false); // Set loading to false once data is fetched from local storage
      //   console.log("Using cached prompts: " + promptIDs);
    } else {
      // If not found in local storage, make the API call
      const url = `${baseUrl}/writing/${taskType}`;
      axios
        .get(url)
        .then((response) => {
          localStorage.setItem(localStorageKey, JSON.stringify(response.data));
          // Update the state with the fetched data
          setAllPromptsData(response.data);
          const promptIDs = response.data.map((item) => item.PromptID);
          setPrompts(promptIDs);
          setLoading(false); // Set loading to false once data is fetched from API
          console.log("Fetched prompts: " + promptIDs);
        })
        .catch((error) => {
          console.error("There was an error fetching the prompts!", error);
          setLoading(false); // Set loading to false even in case of an error
        });
    }
  };

  const handleBackToTasks = () => {
    navigate(`/writing/`);
    setSelectedTask(null);
    setPrompts([]);
  };

  return (
    <div className="speaking">
      <Sidebar className="sidebar" />
      <div className="central-control">
        <CentralControl
          selectedModule="writing"
          moduleDesc="The CELPIP Writing Test assesses your ability to communicate effectively in written English. It consists of two tasks designed to evaluate your writing skills in different contexts. You have a total of 53–60 minutes to complete both tasks. Below is a detailed breakdown of each task:"
          selectedTask={taskType}
          prompts={prompts}
          allPromptsData={allPromptsData}
          onBack={handleBackToTasks}
          onTaskSelect={handleTaskSelect}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Writing;
