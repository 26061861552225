import React, { useEffect, useState } from "react";
import { celpip_speaking_tasks_info } from "../data";
import Task_3_4_8_PracticePage from "./Task_3_4_8_PracticePage";
import WritingPracticePage_Base from "../WritingCelpip/WritingPracticePage_Base";
import Task_1_2_7_PracticePage from "./Task_1_2_7_PracticePage";
import Task_6_PracticePage from "./Task_6_PracticePage";
import Task_5_PracticePage from "./Task_5_PracticePage";
import BackLink from "./BackLink";

const PracticePage_Base = ({
  selectedModule,
  selectedTaskType,
  allPromptList,
  allPromptsData,
  selectedPrompt,
  onBack,
}) => {
  return (
    <React.Fragment>
      <BackLink onBack={onBack} title="Back to Samples" />{" "}
      {selectedModule === "writing" ? (
        <WritingPracticePage_Base
          selectedTaskType={selectedTaskType}
          allPromptList={allPromptList}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={onBack}
        />
      ) : selectedTaskType === "Task6" ? (
        <Task_6_PracticePage
          selectedTaskType={selectedTaskType}
          allPromptList={allPromptList}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={onBack}
        />
      ) : selectedTaskType === "Task5" ? (
        <Task_5_PracticePage
          selectedTaskType={selectedTaskType}
          allPromptList={allPromptList}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={onBack}
        />
      ) : selectedTaskType === "Task3" ||
        selectedTaskType === "Task4" ||
        selectedTaskType === "Task8" ? (
        <Task_3_4_8_PracticePage
          selectedTaskType={selectedTaskType}
          allPromptList={allPromptList}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={onBack}
        />
      ) : (
        <Task_1_2_7_PracticePage
          selectedTaskType={selectedTaskType}
          allPromptList={allPromptList}
          allPromptsData={allPromptsData}
          selectedPrompt={selectedPrompt}
          onBack={onBack}
        />
      )}
    </React.Fragment>
  );
};

export default PracticePage_Base;
